/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import { useIntl } from 'gatsby-plugin-intl';
import { PageHeader, Space } from '../../Widgets';
import { Container, Row, Col } from 'react-bootstrap';
import * as Styles from './Styles.module.scss';
import { StaticImage } from 'gatsby-plugin-image';

interface ICephaloPodsPage {}

const CephaloPodsPage: React.FC<ICephaloPodsPage> = () => {
    const intl = useIntl();

    return (
        <Layout>
            <SEO
                title={intl.formatMessage({
                    id: 'product.cephaloPods.pageTitle',
                })}
                description={intl.formatMessage({
                    id: 'product.cephaloPods.metaDescription',
                })}
                lang={intl.locale}
            />

            <PageHeader
                title={intl.formatMessage({
                    id: 'product.cephaloPods.bannerLabel',
                })}
            />

            <Space height={24} />

            <Container fluid>
                <Row>
                    <Col md={4} xs={12}>
                        <StaticImage
                            src={
                                '../../Resources/Images/Products/CephaloPods/leftImage.jpeg'
                            }
                            className={Styles.leftImage}
                            style={{ objectFit: 'cover' }}
                            alt=""
                        />
                    </Col>
                    <Col md={8} xs={12} className="pageContent">
                        <h1>
                            {intl.formatMessage({
                                id: 'product.cephaloPods.title',
                            })}
                        </h1>

                        <Space height={32} />

                        <p>
                            {intl.formatMessage({
                                id: 'product.cephaloPods.description',
                            })}
                        </p>
                    </Col>
                </Row>
            </Container>

            <Space height={40} />
        </Layout>
    );
};

export default CephaloPodsPage;
